import { createSlice } from "@reduxjs/toolkit"

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notificationList: [],
        notificationAllObject: {},
        notificationFreshData: false,
        notificationTotalEntry: 0,
    }, 
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload
        },
        setNotificationAllObject: (state, action) => {
            state.notificationAllObject = action.payload
        },
        setNotificationFreshList: (state, action) => {
            state.notificationFreshData = action.payload
        },
        setNotificationTotalEntry: (state, action) => {
            state.notificationTotalEntry = action.payload
        },
    
    }
})

export const {
    setNotificationList,
    setNotificationFreshList,
    setNotificationTotalEntry,
    setNotificationAllObject,
} = notificationSlice.actions

export const selectNotificationList = (state) => state.notification.notificationList;
export const selectNotificationFreshList = (state) => state.notification.notificationFreshData
export const selectNotificationTotalEntry = (state) => state.notification.notificationTotalEntry;
export const selectNotificationAllObject = (state) => state.notification.notificationAllObject;

export default notificationSlice.reducer;
