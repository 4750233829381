import logo from '../../assets/images/logo/logo.png'

export default {
    labels: {
        //  appName
        appName: "P-Coins",
        // loginPage
        welcome: 'Welcome to P-Coins Panel',
        menuName: 'P-Coins',
        footerName: 'P-Coins'
    },

    strings: {
        image_description: " Note : Please upload Image less then 2 MB and above 800px resolution.",
        sure_active: "Are you sure you want to activate this user?",
    },

    validations: {
        ice_breaker_message: "Please enter message",
    }
}