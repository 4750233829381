import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userList: [],
        userAllObject: {},
        userFreshData: false,
        userTotalEntry: 0,

        // pcoin transactions
        pcoinTransactionList: [],
        pcoinTransactionFreshData: false,
        pcoinTransactionTotalEntry: 0,

        // user orders
        userOrderList: [],
        userOrderFreshData: false,
        userOrderTotalEntry: 0,

        // user reviews
        userReviewsList: [],
        userReviewsFreshData: false,
        userReviewsTotalEntry: 0,

        // user reviews
        userAddressList: [],
        userAddressFreshData: false,
        userAddressTotalEntry: 0,
    }, 
    reducers: {
        setUserList: (state, action) => {
            state.userList = action.payload
        },
        setUserAllObject: (state, action) => {
            state.userAllObject = action.payload
        },
        setUserFreshList: (state, action) => {
            state.userFreshData = action.payload
        },
        setUserTotalEntry: (state, action) => {
            state.userTotalEntry = action.payload
        },

        // pcoin transaction
        setPcoinTransactionList: (state, action) => {
            state.pcoinTransactionList = action.payload
        },
        setPcoinTransactionFreshData: (state, action) => {
            state.pcoinTransactionFreshData = action.payload
        },
        setPcoinTransactionTotalEntry: (state, action) => {
            state.pcoinTransactionTotalEntry = action.payload
        },

        // user orders
        setUserOrderList: (state, action) => {
            state.userOrderList = action.payload
        },
        setUserOrderFreshData: (state, action) => {
            state.userOrderFreshData = action.payload
        },
        setUserOrderTotalEntry: (state, action) => {
            state.userOrderTotalEntry = action.payload
        },

        // user reviews
        setUserReviewsList: (state, action) => {
            state.userReviewsList = action.payload
        },
        setUserReviewsFreshData: (state, action) => {
            state.userReviewsFreshData = action.payload
        },
        setUserReviewsTotalEntry: (state, action) => {
            state.userReviewsTotalEntry = action.payload
        },

         // user address
         setUserAddressList: (state, action) => {
            state.userAddressList = action.payload
        },
        setUserAddressFreshData: (state, action) => {
            state.userAddressFreshData = action.payload
        },
        setUserAddressTotalEntry: (state, action) => {
            state.userAddressTotalEntry = action.payload
        },

    }
})

export const {

    setUserList,
    setUserFreshList,
    setUserTotalEntry,
    setUserAllObject,

    // pcoin transaction
    setPcoinTransactionList,
    setPcoinTransactionFreshData,
    setPcoinTransactionTotalEntry,

    // user orders
    setUserOrderList,
    setUserOrderFreshData,
    setUserOrderTotalEntry,


    // user reviews
    setUserReviewsList,
    setUserReviewsFreshData,
    setUserReviewsTotalEntry,

    // user address
    setUserAddressList,
    setUserAddressFreshData,
    setUserAddressTotalEntry,

} = userSlice.actions

export const selectUserList = (state) => state.user.userList;
export const selectUserFreshList = (state) => state.user.userFreshData
export const selectUserTotalEntry = (state) => state.user.userTotalEntry;
export const selectUserAllObject = (state) => state.user.userAllObject;

// pcoin transaction
export const selectPcoinTransactionList = (state) => state.user.pcoinTransactionList;
export const selectPcoinTransactionFreshData = (state) => state.user.pcoinTransactionFreshData;
export const selectPcoinTransactionTotalEntry = (state) => state.user.pcoinTransactionTotalEntry;


// user orders
export const selectUserOrderList = (state) => state.user.userOrderList;
export const selectUserOrderFreshData = (state) => state.user.userOrderFreshData;
export const selectUserOrderTotalEntry = (state) => state.user.userOrderTotalEntry;


// user reviews
export const selectUserReviewsList = (state) => state.user.userReviewsList;
export const selectUserReviewsFreshData = (state) => state.user.userReviewsFreshData;
export const selectUserReviewsTotalEntry = (state) => state.user.userReviewsTotalEntry;


// user address
export const selectUserAddressList = (state) => state.user.userAddressList;
export const selectUserAddressFreshData = (state) => state.user.userAddressFreshData;
export const selectUserAddressTotalEntry = (state) => state.user.userAddressTotalEntry;


export default userSlice.reducer;
