import { createSlice } from "@reduxjs/toolkit"

export const foodCategory = createSlice({
    name: "foodCategory",
    initialState: {
        FBCategoryList: [],
        FBCategoryIsFreshData: false,
        FBCategoryTotalEntry: 0,
        FBCategoryId: null,
    }, 
    reducers: {
        setFBCategoryList: (state, action) => {
            state.FBCategoryList = action.payload
        },
        setFBCategoryIsFreshData: (state, action) => {
            state.FBCategoryIsFreshData = action.payload
        },
        setFBCategoryTotalEntry: (state, action) => {
            state.FBCategoryTotalEntry = action.payload
        },
        setFBCategoryId: (state, action) => {
            state.FBCategoryId = action.payload
        }
    }
})

export const {
    setFBCategoryList,
    setFBCategoryTotalEntry,
    setFBCategoryIsFreshData,
    setFBCategoryId
} = foodCategory.actions

export const selectFBCategoryList = (state) => state.foodCategory.FBCategoryList;
export const selectFBCategoryFreshList = (state) => state.foodCategory.FBCategoryIsFreshData;
export const selectFBCategoryTotalEntry = (state) => state.foodCategory.FBCategoryTotalEntry;
export const selectFBCategoryId = (state) => state.foodCategory.FBCategoryId;

export default foodCategory.reducer
