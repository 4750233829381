import { createSlice } from "@reduxjs/toolkit"

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dashboardList: [],
        totalEntry: 0,
    }, 
    reducers: {
        setDashboardList: (state, action) => {
            state.dashboardList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        }
    }
})

export const {
    setDashboardList,
    setTotalEntry,
} = dashboardSlice.actions

export const selectDashboardList = (state) => state.dashboard.dashboardList;
export const selectTotalEntry = (state) => state.dashboard.totalEntry

export default dashboardSlice.reducer
