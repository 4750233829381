import { createSlice } from "@reduxjs/toolkit"

export const multiBrandSlice = createSlice({
    name: "multiBrand",
    initialState: {
        multiBrandList: [],
        AllmultiBrandList: [],
        selectedMultiBrandList: [],
        selectedSubmultiBrandOptions: [],
        selectedSubMultiBrandList: [],
        selectedSubMultiBrandTypeList: [],
        existingSelectedMultiBrand: [],
        existingSelectedSubMultiBrand: [],
        existingSelectedSubMultiBrandType: [],
        showExistingOptions: true,
        multiBrandId: null,
        multiBrandId2: null,
        existingMultiBrandName: '',
        multiBrandName: '',
        subMultiBrandName: '',
        multiBrandTypeName: '',
        multiBrandTypeId: null,
        subCatId: null,
        catTypeId: null,
        subMultiBrandList: [],
        multiBrandTypeList: [],
        multiBrandSelected: [],
        parentSubCatId: 0,
        childSubCatId: 0,
        exportList: [],
        isFreshData: false,
        totalEntry: 0,
        catError: '',
        subCatError: '',
        existingMultiBrand: {},
        existingMultiBrandType: {},
        refreshSubWithUpdate: false,
        existingSubMultiBrand: {},
        
    }, 
    reducers: {
        setMultiBrandList: (state, action) => {
            state.multiBrandList = action.payload
        },
        setAllMultiBrandList: (state, action) => {
            state.AllmultiBrandList = action.payload
        },
        setSelectedMultiBrandList: (state, action) => {
            state.selectedMultiBrandList = action.payload
        },
        setSelectedSubMultiBrandList: (state, action) => {
            state.selectedSubMultiBrandList = action.payload
        },
        setSelectedSubmultiBrandOptions : (state, action) => {
            state.selectedSubmultiBrandOptions = action.payload
        },
        setSelectedSubMultiBrandTypeList: (state, action) => {
            state.selectedSubMultiBrandTypeList = action.payload
        },
        setExistingMultiBrandName: (state, action) => {
            state.existingMultiBrandName = action.payload
        },
        setExistingSelectedMultiBrand: (state, action) => {
            state.existingSelectedMultiBrand = action.payload
        },
        setExistingSelectedSubMultiBrand: (state, action) => {
            state.existingSelectedSubMultiBrand = action.payload
        },
        setExistingSelectedSubMultiBrandType: (state, action) => {
            state.existingSelectedSubMultiBrandType = action.payload
        },
        setShowExistingOptions: (state, action) => {
            state.showExistingOptions = action.payload
        },
        setMultiBrandId: (state, action) => {
            state.multiBrandId = action.payload
        },
        setMultiBrandId2: (state, action) => {
            state.multiBrandId2 = action.payload
        },
        setSubCatId: (state, action) => {
            state.subCatId = action.payload
        },
        setCatTypeId: (state, action) => {
            state.catTypeId = action.payload
        },
        setMultiBrandName: (state, action) => {
            state.multiBrandName = action.payload
        },
        setSubMultiBrandName: (state, action) => {
            state.subMultiBrandName = action.payload
        },
        setMultiBrandTypeName: (state, action) => {
            state.multiBrandTypeName = action.payload
        },
        setMultiBrandSelected: (state, action) => {
            state.multiBrandSelected = action.payload
        },
        setMultiBrandTypeList: (state, action) => {
            state.multiBrandTypeList = action.payload
        },
        setParentSubCatId: (state, action) => {
            state.parentSubCatId = action.payload
        },
        setChildSubCatId: (state, action) => {
            state.childSubCatId = action.payload
        },
        setSubMultiBrandList: (state, action) => {
            state.subMultiBrandList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setMultiBrandTypeId: (state, action) => {
            state.multiBrandTypeId = action.payload
        },
        setCatError: (state, action) => {
            state.catError = action.payload
        },
        setSubCatError: (state, action) => {
            state.subCatError = action.payload
        },
        setExistingMultiBrand: (state, action) => {
            state.existingMultiBrand = action.payload
        },
        setRefreshSubWithUpdate: (state) => {
            state.refreshSubWithUpdate = !state.refreshSubWithUpdate
        },
        setExistingSubMultiBrand: (state, action) => {
            state.existingSubMultiBrand = action.payload
        },
        setExistingMultiBrandType: (state, action) => {
            state.existingMultiBrandType = action.payload
        }
    }
})

export const {
    setMultiBrandList,
    setAllMultiBrandList,
    setSelectedMultiBrandList,
    setSelectedSubMultiBrandList, 
    setSelectedSubMultiBrandTypeList,
    setExistingSelectedMultiBrand,
    setExistingSelectedSubMultiBrand,
    setExistingSelectedSubMultiBrandType,
    setSelectedSubmultiBrandOptions,
    setMultiBrandSelected,
    setSubMultiBrandList,
    setShowExistingOptions, 
    setFreshList,
    setTotalEntry,
    setParentSubCatId,
    setChildSubCatId,
    setMultiBrandTypeList,
    setMultiBrandId,
    setSubCatId,
    setCatTypeId,
    setMultiBrandName,
    setSubMultiBrandName,
    setMultiBrandTypeName,
    setExistingMultiBrandName,
    setMultiBrandId2,
    setMultiBrandTypeId,
    setCatError,
    setSubCatError,
    setExistingMultiBrand,
    setRefreshSubWithUpdate,
    setExistingSubMultiBrand,
    setExistingMultiBrandType
} = multiBrandSlice.actions


export const selectShowExistingOptions = (state) => state.multiBrand.showExistingOptions;
export const selectExistingSelectedMultiBrand = (state) => state.multiBrand.existingSelectedMultiBrand;
export const selectExistingSelectedSubMultiBrand = (state) => state.multiBrand.existingSelectedSubMultiBrand;
export const selectExistingSelectedSubMultiBrandType = (state) => state.multiBrand.existingSelectedSubMultiBrandType;
export const selectMultiBrandList = (state) => state.multiBrand.multiBrandList;
export const selectAllMultiBrandList = (state) => state.multiBrand.AllmultiBrandList;
export const selectSelectedMultiBrandList = (state) => state.multiBrand.selectedMultiBrandList;
export const selectSelectedSubMultiBrandList = (state) => state.multiBrand.selectedSubMultiBrandList;
export const selectSelectedSubMultiBrandTypeList = (state) => state.multiBrand.selectedSubMultiBrandTypeList;
export const selectSubMultiBrandList = (state) => state.multiBrand.subMultiBrandList;
export const selectSelectedSubmultiBrandOptions = (state) => state.multiBrand.selectedSubmultiBrandOptions;
export const selectFreshData = (state) => state.multiBrand.isFreshData;
export const selectTotalEntry = (state) => state.multiBrand.totalEntry;
export const selectMultiBrandSelected = (state) => state.multiBrand.multiBrandSelected;
export const selectParentSubMultiBrandId = (state) => state.multiBrand.parentSubCatId;
export const selectChildSubMultiBrandId = (state) => state.multiBrand.childSubCatId;
export const selectMultiBrandTypeList = (state) => state.multiBrand.multiBrandTypeList;
export const selectMultiBrandId = (state) => state.multiBrand.multiBrandId;
export const selectMultiBrandId2 = (state) => state.multiBrand.multiBrandId2;
export const selectSubCatId = (state) => state.multiBrand.subCatId;
export const selectCatTypeId = (state) => state.multiBrand.catTypeId;
export const selectMultiBrandName = (state) => state.multiBrand.multiBrandName;
export const selectSubMultiBrandName = (state) => state.multiBrand.subMultiBrandName;
export const selectMultiBrandTypeName = (state) => state.multiBrand.multiBrandTypeName;
export const selectExistingMultiBrandName = (state) => state.multiBrand.existingMultiBrandName;
export const selectMultiBrandTypeId = (state) => state.multiBrand.multiBrandTypeId;
export const selectMultiBrandError = (state) => state.multiBrand.catError;
export const selectSubCatError = (state) => state.multiBrand.subCatError;
export const selectExistingMultiBrand = (state) => state.multiBrand.existingMultiBrand;
export const selectRefreshSubWithUpdate = (state) => state.multiBrand.refreshSubWithUpdate;
export const selectExistingSubMultiBrand = (state) => state.multiBrand.existingSubMultiBrand;
export const selectExistingMultiBrandType = (state) => state.multiBrand.existingMultiBrandType;

export default multiBrandSlice.reducer
