import { createSlice } from "@reduxjs/toolkit"

export const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        paymentList: [],
        // exportList: [],
        // isFreshData: false,
        // totalEntry: 0,
    }, 
    reducers: {
        setPaymentList: (state, action) => {
            state.paymentList = action.payload
        },
        // setFreshList: (state, action) => {
        //     state.isFreshData = action.payload
        // },
        // setTotalEntry: (state, action) => {
        //     state.totalEntry = action.payload
        // },
    }
})

export const {
    setPaymentList,
    // setFreshList,
    // setTotalEntry,
} = paymentSlice.actions

export const selectPaymentList = (state) => state.payment.paymentList
// export const selectFreshData = (state) => state.brand.isFreshData
// export const selectTotalEntry = (state) => state.brand.totalEntry

export default paymentSlice.reducer
