import { createSlice } from "@reduxjs/toolkit"

export const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        bannerList: [],
        bannerAllObject: {},
        isFreshData: false,
        totalEntry: 0,
        referenceType: '',
    }, 
    reducers: {
        setBannerList: (state, action) => {
            state.bannerList = action.payload
        },
        setBannerAllObject: (state, action) => {
            state.bannerAllObject = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setReferenceType: (state, action) => {
            state.referenceType = action.payload
        },
    }
})

export const {
    setBannerList,
    setFreshList,
    setTotalEntry,
    setReferenceType,
    setBannerAllObject,
} = bannerSlice.actions

export const selectBannerList = (state) => state.banner.bannerList;
export const selectFreshData = (state) => state.banner.isFreshData
export const selectTotalEntry = (state) => state.banner.totalEntry;
export const selectReferenceType = (state) => state.banner.referenceType;
export const selectBannerAllObject = (state) => state.banner.bannerAllObject;

export default bannerSlice.reducer;
