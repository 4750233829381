import { createSlice } from "@reduxjs/toolkit"

export const categorySlice = createSlice({
    name: "category",
    initialState: {
        categoryList: [],
        AllcategoryList: [],
        selectedCategoryList: [],
        selectedSubcategoryOptions: [],
        selectedSubCategoryList: [],
        selectedSubCategoryTypeList: [],
        existingSelectedCategory: [],
        existingSelectedSubCategory: [],
        existingSelectedSubCategoryType: [],
        showExistingOptions: true,
        categoryId: null,
        categoryId2: null,
        existingCategoryName: '',
        categoryName: '',
        subCategoryName: '',
        categoryTypeName: '',
        categoryTypeId: null,
        subCatId: null,
        catTypeId: null,
        subCategoryList: [],
        categoryTypeList: [],
        categorySelected: [],
        parentSubCatId: 0,
        childSubCatId: 0,
        exportList: [],
        isFreshData: false,
        totalEntry: 0,
        catError: '',
        subCatError: '',
        existingCategory: {},
        existingCategoryType: {},
        refreshSubWithUpdate: false,
        existingSubCategory: {},
        
    }, 
    reducers: {
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setAllCategoryList: (state, action) => {
            state.AllcategoryList = action.payload
        },
        setSelectedCategoryList: (state, action) => {
            state.selectedCategoryList = action.payload
        },
        setSelectedSubCategoryList: (state, action) => {
            state.selectedSubCategoryList = action.payload
        },
        setSelectedSubcategoryOptions : (state, action) => {
            state.selectedSubcategoryOptions = action.payload
        },
        setSelectedSubCategoryTypeList: (state, action) => {
            state.selectedSubCategoryTypeList = action.payload
        },
        setExistingCategoryName: (state, action) => {
            state.existingCategoryName = action.payload
        },
        setExistingSelectedCategory: (state, action) => {
            state.existingSelectedCategory = action.payload
        },
        setExistingSelectedSubCategory: (state, action) => {
            state.existingSelectedSubCategory = action.payload
        },
        setExistingSelectedSubCategoryType: (state, action) => {
            state.existingSelectedSubCategoryType = action.payload
        },
        setShowExistingOptions: (state, action) => {
            state.showExistingOptions = action.payload
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload
        },
        setCategoryId2: (state, action) => {
            state.categoryId2 = action.payload
        },
        setSubCatId: (state, action) => {
            state.subCatId = action.payload
        },
        setCatTypeId: (state, action) => {
            state.catTypeId = action.payload
        },
        setCategoryName: (state, action) => {
            state.categoryName = action.payload
        },
        setSubCategoryName: (state, action) => {
            state.subCategoryName = action.payload
        },
        setCategoryTypeName: (state, action) => {
            state.categoryTypeName = action.payload
        },
        setCategorySelected: (state, action) => {
            state.categorySelected = action.payload
        },
        setCategoryTypeList: (state, action) => {
            state.categoryTypeList = action.payload
        },
        setParentSubCatId: (state, action) => {
            state.parentSubCatId = action.payload
        },
        setChildSubCatId: (state, action) => {
            state.childSubCatId = action.payload
        },
        setSubCategoryList: (state, action) => {
            state.subCategoryList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setCategoryTypeId: (state, action) => {
            state.categoryTypeId = action.payload
        },
        setCatError: (state, action) => {
            state.catError = action.payload
        },
        setSubCatError: (state, action) => {
            state.subCatError = action.payload
        },
        setExistingCategory: (state, action) => {
            state.existingCategory = action.payload
        },
        setRefreshSubWithUpdate: (state) => {
            state.refreshSubWithUpdate = !state.refreshSubWithUpdate
        },
        setExistingSubCategory: (state, action) => {
            state.existingSubCategory = action.payload
        },
        setExistingCategoryType: (state, action) => {
            state.existingCategoryType = action.payload
        }
    }
})

export const {
    setCategoryList,
    setAllCategoryList,
    setSelectedCategoryList,
    setSelectedSubCategoryList, 
    setSelectedSubCategoryTypeList,
    setExistingSelectedCategory,
    setExistingSelectedSubCategory,
    setExistingSelectedSubCategoryType,
    setSelectedSubcategoryOptions,
    setCategorySelected,
    setSubCategoryList,
    setShowExistingOptions, 
    setFreshList,
    setTotalEntry,
    setParentSubCatId,
    setChildSubCatId,
    setCategoryTypeList,
    setCategoryId,
    setSubCatId,
    setCatTypeId,
    setCategoryName,
    setSubCategoryName,
    setCategoryTypeName,
    setExistingCategoryName,
    setCategoryId2,
    setCategoryTypeId,
    setCatError,
    setSubCatError,
    setExistingCategory,
    setRefreshSubWithUpdate,
    setExistingSubCategory,
    setExistingCategoryType
} = categorySlice.actions


export const selectShowExistingOptions = (state) => state.category.showExistingOptions;
export const selectExistingSelectedCategory = (state) => state.category.existingSelectedCategory;
export const selectExistingSelectedSubCategory = (state) => state.category.existingSelectedSubCategory;
export const selectExistingSelectedSubCategoryType = (state) => state.category.existingSelectedSubCategoryType;
export const selectCategoryList = (state) => state.category.categoryList;
export const selectAllCategoryList = (state) => state.category.AllcategoryList;
export const selectSelectedCategoryList = (state) => state.category.selectedCategoryList;
export const selectSelectedSubCategoryList = (state) => state.category.selectedSubCategoryList;
export const selectSelectedSubCategoryTypeList = (state) => state.category.selectedSubCategoryTypeList;
export const selectSubCategoryList = (state) => state.category.subCategoryList;
export const selectSelectedSubcategoryOptions = (state) => state.category.selectedSubcategoryOptions;
export const selectFreshData = (state) => state.category.isFreshData;
export const selectTotalEntry = (state) => state.category.totalEntry;
export const selectCategorySelected = (state) => state.category.categorySelected;
export const selectParentSubCategoryId = (state) => state.category.parentSubCatId;
export const selectChildSubCategoryId = (state) => state.category.childSubCatId;
export const selectCategoryTypeList = (state) => state.category.categoryTypeList;
export const selectCategoryId = (state) => state.category.categoryId;
export const selectCategoryId2 = (state) => state.category.categoryId2;
export const selectSubCatId = (state) => state.category.subCatId;
export const selectCatTypeId = (state) => state.category.catTypeId;
export const selectCategoryName = (state) => state.category.categoryName;
export const selectSubCategoryName = (state) => state.category.subCategoryName;
export const selectCategoryTypeName = (state) => state.category.categoryTypeName;
export const selectExistingCategoryName = (state) => state.category.existingCategoryName;
export const selectCategoryTypeId = (state) => state.category.categoryTypeId;
export const selectCatError = (state) => state.category.catError;
export const selectSubCatError = (state) => state.category.subCatError;
export const selectExistingCategory = (state) => state.category.existingCategory;
export const selectRefreshSubWithUpdate = (state) => state.category.refreshSubWithUpdate;
export const selectExistingSubCategory = (state) => state.category.existingSubCategory;
export const selectExistingCategoryType = (state) => state.category.existingCategoryType;

export default categorySlice.reducer
