import { createSlice } from "@reduxjs/toolkit"

export const couponSlice = createSlice({
    name: "coupon",
    initialState: {
        couponList: [],
        couponAllObject: {},
        couponFreshData: false,
        couponTotalEntry: 0,
    }, 
    reducers: {
        setCouponList: (state, action) => {
            state.couponList = action.payload
        },
        setCouponAllObject: (state, action) => {
            state.couponAllObject = action.payload
        },
        setCouponFreshList: (state, action) => {
            state.couponFreshData = action.payload
        },
        setCouponTotalEntry: (state, action) => {
            state.couponTotalEntry = action.payload
        },
    
    }
})

export const {
    setCouponList,
    setCouponFreshList,
    setCouponTotalEntry,
    setCouponAllObject,
} = couponSlice.actions

export const selectCouponList = (state) => state.coupon.couponList;
export const selectCouponFreshList = (state) => state.coupon.couponFreshData
export const selectCouponTotalEntry = (state) => state.coupon.couponTotalEntry;
export const selectCouponAllObject = (state) => state.coupon.couponAllObject;

export default couponSlice.reducer;
