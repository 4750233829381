import { createSlice } from "@reduxjs/toolkit";

export const foodBrandSlice = createSlice({

    name: "foodBrand",
    initialState: {

    // FOOD BRAND
    fBList: [],
    fBObj: {},
    fBIsFreshData: false,
    fBTotalEntry: 0,
    fbEditClick: false,
    fbBrandId: null,

    // OUTLET
    fbOutletId: null,
    outletObj: {},
    outletList: [],
    outletTable: [],
    outletIsFreshData: false,
    outletTotalEntry: 0,
    outletTableFreshData: false,
    outletTableTotalEntry: 0,
    outletDescriptionHtml: '',

    // MENU
    menuDescriptionHtml: '',
    outletMenuItemObj: {},
    outletMenuList: [],
    outletMenuFreshData: false,
    outletMenuTotalEntry: 0,

    }, 
    reducers: {
        setOutletTableTotalEntry: (state, action) => {
            state.outletTableTotalEntry = action.payload
        },
        setOutletTableFreshData: (state, action) => {
            state.outletTableFreshData = action.payload
        },
        setFBList: (state, action) => {
            state.fBList = action.payload
        },
        setOutletTable: (state, action) => {
            state.outletTable = action.payload
        },
        setOutletObj: (state, action) => {
            state.outletObj = action.payload
        },
        setFBObj: (state, action) => {
            state.fBObj = action.payload
        },
        setFbBrandId: (state, action) => {
            state.fbBrandId = action.payload
        },
        setFbEditClick: (state, action) => {
            state.fbEditClick = action.payload
        },
        setOutletList: (state, action) => {
            state.outletList = action.payload
        },
        setFBFreshList: (state, action) => {
            state.fBIsFreshData = action.payload
        },
        setFBTotalEntry: (state, action) => {
            state.fBTotalEntry = action.payload
        },
        setOutletFreshList: (state, action) => {
            state.outletIsFreshData = action.payload
        }, 
        setOutletTotalEntry: (state, action) => {
            state.outletTotalEntry = action.payload
        },
        setFbOutletId: (state, action) => {
            state.fbOutletId = action.payload
        },
        setOutletDescriptionHtml: (state, action) => {
            state.outletDescriptionHtml = action.payload
        },

        // MENU
        setMenuDescriptionHtml: (state, action) => {
            state.menuDescriptionHtml = action.payload
        },
        setOutletMenuItemObj: (state, action) => {
            state.outletMenuItemObj = action.payload
        },
        setOutletMenuList: (state, action) => {
            state.outletMenuList = action.payload
        },
        setOutletMenuFreshData: (state, action) => {
            state.outletMenuFreshData = action.payload
        },
        setOutletMenuTotalEntry: (state, action) => {
            state.outletMenuTotalEntry = action.payload
        },
    }
})

export const {
    setFBFreshList,
    setOutletList,
    setFBTotalEntry,
    setFBList,
    setOutletFreshList,
    setOutletTotalEntry,
    setFbEditClick,
    setFbBrandId,
    setOutletObj,
    setFBObj,
    setOutletTable,
    setOutletTableFreshData,
    setOutletTableTotalEntry,
    setFbOutletId,
    setOutletDescriptionHtml,

    // MENU
    setMenuDescriptionHtml,
    setOutletMenuItemObj,
    setOutletMenuTotalEntry,
    setOutletMenuList,
    setOutletMenuFreshData,


} = foodBrandSlice.actions

export const selectFBList = (state) => state.foodBrand.fBList;
export const selectFBObj = (state) => state.foodBrand.fBObj;
export const selectFBFreshData = (state) => state.foodBrand.fBIsFreshData;
export const selectFBTotalEntry = (state) => state.foodBrand.fBTotalEntry;

export const selectOutletList = (state) => state.foodBrand.outletList;
export const selectOutletFreshData = (state) => state.foodBrand.outletIsFreshData;
export const selectOutletTotalEntry = (state) => state.foodBrand.outletTotalEntry;
export const selectOutletObj = (state) => state.foodBrand.outletObj;
export const selectOutletTable = (state) => state.foodBrand.outletTable;
export const selectOutletTableFreshData = (state) => state.foodBrand.outletTableFreshData;
export const selectOutletTableTotalEntry = (state) => state.foodBrand.outletTableTotalEntry;
export const selectOutletDescriptionHtml = (state) => state.foodBrand.outletDescriptionHtml;

export const selectFbEditClick = (state) => state.foodBrand.fbEditClick;
export const selectFbBrandId = (state) => state.foodBrand.fbBrandId;
export const selectFbOutletId = (state) => state.foodBrand.fbOutletId;

// MENU MENU

export const selectMenuDescriptionHtml = (state) => state.foodBrand.menuDescriptionHtml;
export const selectOutletMenuItemObj = (state) => state.foodBrand.outletMenuItemObj;
export const selectOutletMenuList = (state) => state.foodBrand.outletMenuList;
export const selectOutletMenuFreshData = (state) => state.foodBrand.outletMenuFreshData;
export const selectOutletMenuTotalEntry = (state) => state.foodBrand.outletMenuTotalEntry;


export default foodBrandSlice.reducer;
