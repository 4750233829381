import { createSlice } from "@reduxjs/toolkit"

export const brandSlice = createSlice({
    name: "brand",
    initialState: {
        brandList: [],
        brandIds: [],
        existingBrands: [],
        exportList: [],
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setBrandList: (state, action) => {
            state.brandList = action.payload
        },
        setExistingBrands: (state, action) => {
            state.existingBrands = action.payload
        },
        setBrandIds: (state, action) => {
            state.brandIds = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
})

export const {
    setBrandList,
    setFreshList,
    setTotalEntry,
    setBrandIds,
    setExistingBrands,
} = brandSlice.actions

export const selectBrandList = (state) => state.brand.brandList
export const selectFreshData = (state) => state.brand.isFreshData
export const selectTotalEntry = (state) => state.brand.totalEntry;
export const selectBrandIds = (state) => state.brand.brandIds;
export const selectExistingBrands = (state) => state.brand.existingBrands;


export default brandSlice.reducer
