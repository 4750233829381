// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// moment js
import moment from 'moment';

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context
import ability from './configs/acl/ability'; // subMenu
import { AbilityContext } from './utility/context/Can';  // subMenu
import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** i18n
import './configs/i18n'

// ** Toast
import { Toaster } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"
  
// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"
import "../src/assets/css/style.css"
import "../src/assets/css/flatpickr.min.css";
import './assets/scss/addModule.scss';
import './assets/scss/brandDropdown.scss';
import './assets/scss/categoryDropdown.scss';
import './assets/scss/singleVariationForm.scss';
import './assets/scss/addVariation.scss';
import './assets/scss/updateVariant.scss';
import './assets/scss/textEditor.scss';
import './assets/scss/productDetails.scss';
import './assets/scss/editProduct.scss';
import './assets/scss/variationForms.scss';
import './assets/scss/deleteReview.scss';
import './assets/scss/addFlashSale.scss';
import './assets/scss/orderManagement.scss';
import './assets/scss/TrackingForm.scss';

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: "react-hot-toast" }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
