import { createSlice } from "@reduxjs/toolkit"

export const digitalCodeSlice = createSlice({
    name: "digitalCode",
    initialState: {

        // digital codes listing
        digitalCodeList: [],
        digitalCodeAllObject: {},
        digitalCodeFreshData: false,
        digitalCodeTotalEntry: 0,

        // specific digital codes listing

        specificDigitalCodeList: [],
        specificDigitalCodeAllObject: {},
        specificDigitalCodeFreshData: false,
        specificDigitalCodeTotalEntry: 0,

        // Category
        digitalCodeCategoryId: null,
        digitalCodeCategoryList: [],
        digitalCodeCategoryAllObject: {},
        digitalCodeCategoryFreshData: false,
        digitalCodeCategoryTotalEntry: 0,
    }, 
    reducers: {
        setDigitalCodeList: (state, action) => {
            state.digitalCodeList = action.payload
        },
        setDigitalCodeAllObject: (state, action) => {
            state.digitalCodeAllObject = action.payload
        },
        setDigitalCodeFreshList: (state, action) => {
            state.digitalCodeFreshData = action.payload
        },
        setDigitalCodeTotalEntry: (state, action) => {
            state.digitalCodeTotalEntry = action.payload
        },

        // specific digital codes listing

        setSpecificDigitalCodeList: (state, action) => {
            state.specificDigitalCodeList = action.payload
        },
        setSpecificDigitalCodeAllObject: (state, action) => {
            state.specificDigitalCodeAllObject = action.payload
        },
        setSpecificDigitalCodeFreshList: (state, action) => {
            state.specificDigitalCodeFreshData = action.payload
        },
        setSpecificDigitalCodeTotalEntry: (state, action) => {
            state.specificDigitalCodeTotalEntry = action.payload
        },

        // Category

        setDigitalCodeCategoryId: (state, action) => {
            state.digitalCodeCategoryId = action.payload
        },
        setDigitalCodeCategoryList: (state, action) => {
            state.digitalCodeCategoryList = action.payload
        },
        setDigitalCodeCategoryAllObject: (state, action) => {
            state.digitalCodeCategoryAllObject = action.payload
        },
        setDigitalCodeCategoryFreshList: (state, action) => {
            state.digitalCodeCategoryFreshData = action.payload
        },
        setDigitalCodeCategoryTotalEntry: (state, action) => {
            state.digitalCodeCategoryTotalEntry = action.payload
        },
    
    }
})

export const {
    setDigitalCodeList,
    setDigitalCodeFreshList,
    setDigitalCodeTotalEntry,
    setDigitalCodeAllObject,

    // specific digital codes listing
    setSpecificDigitalCodeList,
    setSpecificDigitalCodeFreshList,
    setSpecificDigitalCodeTotalEntry,
    setSpecificDigitalCodeAllObject,

    // Category 

    setDigitalCodeCategoryId,
    setDigitalCodeCategoryList,
    setDigitalCodeCategoryFreshList,
    setDigitalCodeCategoryTotalEntry,
    setDigitalCodeCategoryAllObject,
} = digitalCodeSlice.actions

export const selectDigitalCodeList = (state) => state.digitalCode.digitalCodeList;
export const selectDigitalCodeFreshList = (state) => state.digitalCode.digitalCodeFreshData
export const selectDigitalCodeTotalEntry = (state) => state.digitalCode.digitalCodeTotalEntry;
export const selectDigitalCodeAllObject = (state) => state.digitalCode.digitalCodeAllObject;

// specific digital codes listing

export const selectSpecificDigitalCodeList = (state) => state.digitalCode.specificDigitalCodeList;
export const selectSpecificDigitalCodeFreshList = (state) => state.digitalCode.specificDigitalCodeFreshData
export const selectSpecificDigitalCodeTotalEntry = (state) => state.digitalCode.specificDigitalCodeTotalEntry;
export const selectSpecificDigitalCodeAllObject = (state) => state.digitalCode.specificDigitalCodeAllObject;

// digital code category

export const selectDigitalCodeCategoryId = (state) => state.digitalCode.digitalCodeCategoryId;
export const selectDigitalCodeCategoryList = (state) => state.digitalCode.digitalCodeCategoryList;
export const selectDigitalCodeCategoryFreshList = (state) => state.digitalCode.digitalCodeCategoryFreshData
export const selectDigitalCodeCategoryTotalEntry = (state) => state.digitalCode.digitalCodeCategoryTotalEntry;
export const selectDigitalCodeCategoryAllObject = (state) => state.digitalCode.digitalCodeCategoryAllObject;

export default digitalCodeSlice.reducer;
