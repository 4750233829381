import { createSlice } from "@reduxjs/toolkit"

export const orderSlice = createSlice({
    name: "order",
    initialState: {
        orderList: [],
        rowData: [],
        orderStatus: '',
        trackingNo: '',
        orderId: null,
        orderDetail: {},
        tabFilter: '',
        referenceType: '',
        // exportList: [],
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setRowList: (state, action) => {
            state.rowData = action.payload
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload
        },
        setOrderStatus: (state, action) => {
            state.orderStatus = action.payload
        },
        setTrackingNo: (state, action) => {
            state.trackingNo = action.payload
        },
        setOrderId: (state, action) => {
            state.orderId = action.payload;
        },
        setTabFilter: (state, action) => {
            state.tabFilter = action.payload;
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
})

export const {
    setOrderList,
    setOrderStatus,
    setTrackingNo,
    setOrderId,
    setOrderDetail,
    setTabFilter,
    setFreshList,
    setTotalEntry,
    setRowList
} = orderSlice.actions

export const selectOrderList = (state) => state.order.orderList;
export const selectRowList = (state) => state.order.rowData;
export const selectOrderStatus = (state) => state.order.orderStatus;
export const selectTrackingNo = (state) => state.order.trackingNo;
export const selectOrderId = (state) => state.order.orderId;
export const selectOrderDetail = (state) => state.order.orderDetail;
export const selectTabFilter = (state) => state.order.tabFilter;

export const selectFreshData = (state) => state.order.isFreshData
export const selectTotalEntry = (state) => state.order.totalEntry

export default orderSlice.reducer
