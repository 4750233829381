import { createSlice } from "@reduxjs/toolkit"

export const voucherSlice = createSlice({
    name: "voucher",
    initialState: {
        voucherList: [],
        voucherAllObject: {},
        voucherFreshData: false,
        voucherTotalEntry: 0,
    }, 
    reducers: {
        setVoucherList: (state, action) => {
            state.voucherList = action.payload
        },
        setVoucherAllObject: (state, action) => {
            state.voucherAllObject = action.payload
        },
        setVoucherFreshList: (state, action) => {
            state.voucherFreshData = action.payload
        },
        setVoucherTotalEntry: (state, action) => {
            state.voucherTotalEntry = action.payload
        },
    
    }
})

export const {
    setVoucherList,
    setVoucherFreshList,
    setVoucherTotalEntry,
    setVoucherAllObject,
} = voucherSlice.actions

export const selectVoucherList = (state) => state.voucher.voucherList;
export const selectVoucherFreshList = (state) => state.voucher.voucherFreshData
export const selectVoucherTotalEntry = (state) => state.voucher.voucherTotalEntry;
export const selectVoucherAllObject = (state) => state.voucher.voucherAllObject;

export default voucherSlice.reducer;
