import { createSlice } from "@reduxjs/toolkit"

export const pCoinSlice = createSlice({
    name: "pCoin",
    initialState: {
        pCoinList: [],
        isFreshData: false,
        totalEntry: 0,
        referenceType: '',
    }, 
    reducers: {
        setPCoinList: (state, action) => {
            state.pCoinList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setReferenceType: (state, action) => {
            state.referenceType = action.payload
        },
    }
})

export const {
    setPCoinList,
    setFreshList,
    setTotalEntry,
    setReferenceType,
} = pCoinSlice.actions

export const selectPCoinList = (state) => state.pCoin.pCoinList;
export const selectFreshData = (state) => state.pCoin.isFreshData;
export const selectTotalEntry = (state) => state.pCoin.totalEntry;
export const selectReferenceType = (state) => state.pCoin.referenceType;

export default pCoinSlice.reducer;
