import { createSlice } from "@reduxjs/toolkit"

export const outletExploreSlice = createSlice({
    name: "outletExplore",
    initialState: {
        outletExploreList: [],
        outletExploreAllObject: {},
        outletExploreFreshData: false,
        outletExploreTotalEntry: 0,
    }, 
    reducers: {
        setOutletExploreList: (state, action) => {
            state.outletExploreList = action.payload
        },
        setOutletExploreAllObject: (state, action) => {
            state.outletExploreAllObject = action.payload
        },
        setOutletExploreFreshList: (state, action) => {
            state.outletExploreFreshData = action.payload
        },
        setOutletExploreTotalEntry: (state, action) => {
            state.outletExploreTotalEntry = action.payload
        },
    
    }
})

export const {
    setOutletExploreList,
    setOutletExploreFreshList,
    setOutletExploreTotalEntry,
    setOutletExploreAllObject,
} = outletExploreSlice.actions

export const selectOutletExploreList = (state) => state.outletExplore.outletExploreList;
export const selectOutletExploreFreshList = (state) => state.outletExplore.outletExploreFreshData
export const selectOutletExploreTotalEntry = (state) => state.outletExplore.outletExploreTotalEntry;
export const selectOutletExploreAllObject = (state) => state.outletExplore.outletExploreAllObject;

export default outletExploreSlice.reducer;
